import { useState, useEffect } from "react";
import { Container, Form, Button, Alert, Spinner } from "react-bootstrap";
import "./Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { Helmet } from "react-helmet-async";
import authService from "../../services/auth.service";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import logo_dark from "../../images/logo_white.png";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            window.location.href = "/dashboard";
        }

        const queryParams = new URLSearchParams(window.location.search);
        const tokenFromURL = queryParams.get("token");
        const emailFromURL = queryParams.get("email");
        const avatarFromURL = queryParams.get("avatar");
        const firstnameFromURL = queryParams.get("firstname");
        const lastnameFromURL = queryParams.get("lastname");

        if (tokenFromURL && emailFromURL && avatarFromURL) {
            localStorage.setItem("token", tokenFromURL);
            localStorage.setItem("email", emailFromURL);
            localStorage.setItem("avatar", avatarFromURL);
            localStorage.setItem("firstname", firstnameFromURL);
            localStorage.setItem("lastname", lastnameFromURL);
            localStorage.setItem("xad", true);
            localStorage.setItem("role", "imposter");

            window.location.href = "/dashboard";
        }
    }, []);

    const handleLogin = async (event) => {
        event.preventDefault();
        setIsLoggingIn(true);
        setLoginError(null);
    
        // Get Turnstile response token
        const turnstileToken = document.querySelector('[name="cf-turnstile-response"]').value;
    
        if (!turnstileToken) {
            setLoginError("Please complete the Turnstile challenge.");
            setIsLoggingIn(false);
            return;
        }
    
        try {
            const loginDetails = { email, password, rememberMe, turnstileToken };
            const response = await authService.login(loginDetails);
    
            if (response.email) {
                setLoginError(null);
                setLoginSuccess(true);
    
                const role = response.role_id === 1 ? "User" : response.role_id === 2 ? "Administrator" : "Unknown Role";
    
                localStorage.setItem("email", response.email);
                localStorage.setItem("avatar", response.avatar);
                localStorage.setItem("firstname", response.firstname);
                localStorage.setItem("lastname", response.lastname);
                localStorage.setItem("role", role);
                localStorage.setItem("token", response.token);
                localStorage.setItem("xad", true);
    
                setTimeout(() => {
                    setIsLoggingIn(false);
                    window.location.href = "/dashboard";
                }, 2000);
            } else if (response.message === "2fa") {
                localStorage.setItem("xad", false);
                localStorage.setItem("id", response.id);
                window.location.href = "/login/2fa";
            } else if (response.message === "email") {
                localStorage.setItem("xad", false);
                window.location.href = "/login/confirmemail";
            } else {
                setLoginError("Invalid credentials. Please try again.");
            }
        } catch (error) {
            setLoginError("An error occurred during login. Please check your details and try again.");
        }
    };

    return (
        <div>
            <Helmet>
                <title>Centnodes | Login to your account</title>
                <meta name="description" content="Login to your account." />
                <script src="https://challenges.cloudflare.com/turnstile/v0/api.js" async defer></script>

            </Helmet>
            <div className="hero-short p-1">
                <div className="container mt-5 justify-content-center d-flex">
                    <img src={logo_dark} className="loginLogo img-fluid" alt="Company Logo" />
                </div>
            </div>
            <Container>
                <div className="row d-flex justify-content-center loginRow pb-4">
                    <div className="col-12 col-md-8 col-lg-6">
                        <div className="loginBox bg-light border p-4 text-center">
                            <h4 className="h4 color-primary mt-3">Login to your account</h4>
                            <p className="color-gray">Enter your email & password to login.</p>

                            {loginError && (
                                <Alert variant="danger">
                                    <strong>Error:</strong> {loginError}
                                </Alert>
                            )}
                            {loginSuccess && (
                                <Alert variant="success">
                                    <strong>Success:</strong> Logging you in...
                                </Alert>
                            )}

                            <Form onSubmit={handleLogin}>
                                <Form.Group className="text-start d-grid gap-2">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <Form.Label className="mt-2">Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter your password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                    <small>
                                        <a href="/password/reset-request/">Forgot your password?</a>
                                    </small>
                                    <Form.Check
                                        className="mt-3"
                                        type="checkbox"
                                        id="loginRememberMe"
                                        label="Remember Me"
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                    />
                                </Form.Group>
                                <div className="cf-turnstile" data-sitekey="0x4AAAAAAA1bTpZsdge-ysow"></div>

                                <div className="d-grid mt-4">
                                    <Button type="submit" disabled={isLoggingIn} className="btn btn-centhost">
                                        {isLoggingIn ? <Spinner animation="border" size="sm" /> : "Login"}
                                    </Button>
                                </div>
                            </Form>

                            <ul className="mt-4">
                                <li>
                                    <a href="/register">
                                        <FontAwesomeIcon className="me-2" icon={faUserAlt} /> No account yet? Sign up
                                    </a>
                                </li>
                                <li>
                                    <a href="https://centnodes.com">
                                        <FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} /> Back to homepage
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Login;
