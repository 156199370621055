import { useState, useEffect } from "react";
import { Container, Form, Button, Alert, Spinner } from "react-bootstrap";
import "./Register.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { Helmet } from "react-helmet-async";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import logo_dark from "../../images/logo_white.png";
import authService from "../../services/auth.service";

function Register() {
    const [registerError, setRegisterError] = useState(null);
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);

    const registerStepOne = (e) => {
        e.preventDefault();
        setRegisterError(null);

        const email = e.target[0].value;
        const passwordInput = e.target[1].value;
        const passwordConfirm = e.target[2].value;

        if (passwordInput.length < 8) {
            setRegisterError("Password length must be at least 8 characters.");
            return;
        }
        if (passwordInput !== passwordConfirm) {
            setRegisterError("Passwords do not match.");
            return;
        }

        setPassword(passwordInput);
        setUsername(email);

        document.getElementById("registerStepOne").classList.add("d-none");
        document.getElementById("registerStepTwo").classList.remove("d-none");
    };

    const registerStepTwo = async (e) => {
        e.preventDefault();
        setRegisterError(null);
        setRegisterSuccess(false);
        setIsProcessing(true);

        const acceptTerms = e.target[8].checked;

        if (!acceptTerms) {
            setRegisterError("Please accept the Terms of Service.");
            setIsProcessing(false);
            return;
        }

        const user = {
            email: username,
            password: password,
            firstname: e.target[0].value,
            lastname: e.target[1].value,
            state: e.target[2].value,
            city: e.target[3].value,
            street: e.target[4].value,
            street_number: e.target[5].value,
            zip: e.target[6].value,
            country: e.target[7].value,
        };

        try {
            const createUser = await authService.register(user);

            if (!createUser) {
                setRegisterError("Something went wrong while registering.");
            } else if (createUser === "email taken") {
                setRegisterError("Email is already in use. Please try another.");
            } else {
                setRegisterSuccess(true);
                setTimeout(() => {
                    window.location.assign("/login");
                }, 5000);
            }
        } catch (err) {
            console.error(err);
            setRegisterError("An error occurred while registering.");
        } finally {
            setIsProcessing(false);
        }
    };

    useEffect(() => { }, []);

    return (
        <div>
            <Helmet>
                <title>Centnodes | Create an account</title>
                <meta name="description" content="Create an account to access Centnodes services." />
            </Helmet>
            <div className="hero-short p-1">
                <div className="container mt-5 justify-content-center d-flex">
                    <img src={logo_dark} className="loginLogo img-fluid" alt="Company Logo" />
                </div>
            </div>
            <Container>
                <div className="row d-flex justify-content-center loginRow pb-4">
                    <div className="col-12 col-md-8">
                        <div className="loginBox bg-light border p-4 text-center">
                            <h4 className="h4 color-primary mt-3">Create an account</h4>
                            <p className="color-gray">Fill in the required fields below.</p>

                            {registerError && (
                                <Alert variant="danger">
                                    <strong>Error:</strong> {registerError}
                                </Alert>
                            )}
                            {registerSuccess && (
                                <Alert variant="success">
                                    Success! Redirecting to login page...
                                </Alert>
                            )}

                            <Form id="registerStepOne" onSubmit={registerStepOne}>
                                <Form.Group className="p-4 pb-0 text-start d-grid gap-2">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" id="registerEmail" required placeholder="Enter your email" />
                                </Form.Group>

                                <Form.Group className="p-4 text-start d-grid gap-2">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        id="registerPassword"
                                        required
                                        placeholder="Enter your password"
                                    />
                                    <Form.Label className="mt-2">Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        id="confirmPassword"
                                        required
                                        placeholder="Re-enter your password"
                                    />
                                </Form.Group>

                                <div className="d-grid">
                                    <Button type="submit" className="btn btn-centhost">
                                        Next
                                    </Button>
                                </div>
                            </Form>

                            <Form id="registerStepTwo" className="d-none" onSubmit={registerStepTwo}>
                                <Form.Group className="p-4 pb-0 pt-0 text-start d-grid gap-2">
                                    <div className="row">
                                        <div className="col">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control type="text" id="registerFirstName" required placeholder="John" />
                                        </div>
                                        <div className="col">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" id="registerLastName" required placeholder="Doe" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control type="text" id="registerState" required placeholder="California" />
                                        </div>
                                        <div className="col">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control type="text" id="registerCity" required placeholder="Los Angeles" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Form.Label>Street</Form.Label>
                                            <Form.Control type="text" id="registerStreet" required placeholder="Main St" />
                                        </div>
                                        <div className="col">
                                            <Form.Label>Number</Form.Label>
                                            <Form.Control type="text" id="registerStreetNumber" required placeholder="123" />
                                        </div>
                                        <div className="col">
                                            <Form.Label>ZIP Code</Form.Label>
                                            <Form.Control type="text" id="registerZIP" required placeholder="90001" />
                                        </div>
                                    </div>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select id="registerCountry" required>
                                        <option value="AF">Afghanistan</option>
                                        <option value="AL">Albania</option>
                                        <option value="DZ">Algeria</option>
                                        <option value="AS">American Samoa</option>
                                        <option value="AD">Andorra</option>
                                        <option value="AO">Angola</option>
                                        <option value="AI">Anguilla</option>
                                        <option value="AG">Antigua and Barbuda</option>
                                        <option value="AR">Argentina</option>
                                        <option value="AM">Armenia</option>
                                        <option value="AW">Aruba</option>
                                        <option value="AU">Australia</option>
                                        <option value="AT">Austria</option>
                                        <option value="AZ">Azerbaijan</option>
                                        <option value="BS">Bahamas</option>
                                        <option value="BH">Bahrain</option>
                                        <option value="BD">Bangladesh</option>
                                        <option value="BB">Barbados</option>
                                        <option value="BY">Belarus</option>
                                        <option value="BE">Belgium</option>
                                        <option value="BZ">Belize</option>
                                        <option value="BJ">Benin</option>
                                        <option value="BM">Bermuda</option>
                                        <option value="BT">Bhutan</option>
                                        <option value="BO">Bolivia</option>
                                        <option value="BA">Bosnia and Herzegovina</option>
                                        <option value="BW">Botswana</option>
                                        <option value="BR">Brazil</option>
                                        <option value="BN">Brunei</option>
                                        <option value="BG">Bulgaria</option>
                                        <option value="BF">Burkina Faso</option>
                                        <option value="BI">Burundi</option>
                                        <option value="KH">Cambodia</option>
                                        <option value="CM">Cameroon</option>
                                        <option value="CA">Canada</option>
                                        <option value="CV">Cape Verde</option>
                                        <option value="KY">Cayman Islands</option>
                                        <option value="CF">Central African Republic</option>
                                        <option value="TD">Chad</option>
                                        <option value="CL">Chile</option>
                                        <option value="CN">China</option>
                                        <option value="CO">Colombia</option>
                                        <option value="KM">Comoros</option>
                                        <option value="CG">Congo</option>
                                        <option value="CD">Congo, Democratic Republic of</option>
                                        <option value="CR">Costa Rica</option>
                                        <option value="CI">Côte d'Ivoire</option>
                                        <option value="HR">Croatia</option>
                                        <option value="CU">Cuba</option>
                                        <option value="CY">Cyprus</option>
                                        <option value="CZ">Czech Republic</option>
                                        <option value="DK">Denmark</option>
                                        <option value="DJ">Djibouti</option>
                                        <option value="DM">Dominica</option>
                                        <option value="DO">Dominican Republic</option>
                                        <option value="EC">Ecuador</option>
                                        <option value="EG">Egypt</option>
                                        <option value="SV">El Salvador</option>
                                        <option value="GQ">Equatorial Guinea</option>
                                        <option value="ER">Eritrea</option>
                                        <option value="EE">Estonia</option>
                                        <option value="SZ">Eswatini</option>
                                        <option value="ET">Ethiopia</option>
                                        <option value="FJ">Fiji</option>
                                        <option value="FI">Finland</option>
                                        <option value="FR">France</option>
                                        <option value="GA">Gabon</option>
                                        <option value="GM">Gambia</option>
                                        <option value="GE">Georgia</option>
                                        <option value="DE">Germany</option>
                                        <option value="GH">Ghana</option>
                                        <option value="GR">Greece</option>
                                        <option value="GD">Grenada</option>
                                        <option value="GT">Guatemala</option>
                                        <option value="GN">Guinea</option>
                                        <option value="GW">Guinea-Bissau</option>
                                        <option value="GY">Guyana</option>
                                        <option value="HT">Haiti</option>
                                        <option value="HN">Honduras</option>
                                        <option value="HU">Hungary</option>
                                        <option value="IS">Iceland</option>
                                        <option value="IN">India</option>
                                        <option value="ID">Indonesia</option>
                                        <option value="IR">Iran</option>
                                        <option value="IQ">Iraq</option>
                                        <option value="IE">Ireland</option>
                                        <option value="IL">Israel</option>
                                        <option value="IT">Italy</option>
                                        <option value="JM">Jamaica</option>
                                        <option value="JP">Japan</option>
                                        <option value="JO">Jordan</option>
                                        <option value="KZ">Kazakhstan</option>
                                        <option value="KE">Kenya</option>
                                        <option value="KI">Kiribati</option>
                                        <option value="KW">Kuwait</option>
                                        <option value="KG">Kyrgyzstan</option>
                                        <option value="LA">Laos</option>
                                        <option value="LV">Latvia</option>
                                        <option value="LB">Lebanon</option>
                                        <option value="LS">Lesotho</option>
                                        <option value="LR">Liberia</option>
                                        <option value="LY">Libya</option>
                                        <option value="LI">Liechtenstein</option>
                                        <option value="LT">Lithuania</option>
                                        <option value="LU">Luxembourg</option>
                                        <option value="MG">Madagascar</option>
                                        <option value="MW">Malawi</option>
                                        <option value="MY">Malaysia</option>
                                        <option value="MV">Maldives</option>
                                        <option value="ML">Mali</option>
                                        <option value="MT">Malta</option>
                                        <option value="MH">Marshall Islands</option>
                                        <option value="MR">Mauritania</option>
                                        <option value="MU">Mauritius</option>
                                        <option value="MX">Mexico</option>
                                        <option value="FM">Micronesia</option>
                                        <option value="MD">Moldova</option>
                                        <option value="MC">Monaco</option>
                                        <option value="MN">Mongolia</option>
                                        <option value="ME">Montenegro</option>
                                        <option value="MA">Morocco</option>
                                        <option value="MZ">Mozambique</option>
                                        <option value="MM">Myanmar</option>
                                        <option value="NA">Namibia</option>
                                        <option value="NR">Nauru</option>
                                        <option value="NP">Nepal</option>
                                        <option value="NL">Netherlands</option>
                                        <option value="NZ">New Zealand</option>
                                        <option value="NI">Nicaragua</option>
                                        <option value="NE">Niger</option>
                                        <option value="NG">Nigeria</option>
                                        <option value="NO">Norway</option>
                                        <option value="OM">Oman</option>
                                        <option value="PK">Pakistan</option>
                                        <option value="PW">Palau</option>
                                        <option value="PA">Panama</option>
                                        <option value="PG">Papua New Guinea</option>
                                        <option value="PY">Paraguay</option>
                                        <option value="PE">Peru</option>
                                        <option value="PH">Philippines</option>
                                        <option value="PL">Poland</option>
                                        <option value="PT">Portugal</option>
                                        <option value="QA">Qatar</option>
                                        <option value="RO">Romania</option>
                                        <option value="RU">Russia</option>
                                        <option value="RW">Rwanda</option>
                                        <option value="WS">Samoa</option>
                                        <option value="SM">San Marino</option>
                                        <option value="ST">Sao Tome and Principe</option>
                                        <option value="SA">Saudi Arabia</option>
                                        <option value="SN">Senegal</option>
                                        <option value="RS">Serbia</option>
                                        <option value="SC">Seychelles</option>
                                        <option value="SL">Sierra Leone</option>
                                        <option value="SG">Singapore</option>
                                        <option value="SK">Slovakia</option>
                                        <option value="SI">Slovenia</option>
                                        <option value="SB">Solomon Islands</option>
                                        <option value="SO">Somalia</option>
                                        <option value="ZA">South Africa</option>
                                        <option value="KR">South Korea</option>
                                        <option value="ES">Spain</option>
                                        <option value="LK">Sri Lanka</option>
                                        <option value="SD">Sudan</option>
                                        <option value="SR">Suriname</option>
                                        <option value="SE">Sweden</option>
                                        <option value="CH">Switzerland</option>
                                        <option value="SY">Syria</option>
                                        <option value="TW">Taiwan</option>
                                        <option value="TJ">Tajikistan</option>
                                        <option value="TZ">Tanzania</option>
                                        <option value="TH">Thailand</option>
                                        <option value="TL">Timor-Leste</option>
                                        <option value="TG">Togo</option>
                                        <option value="TO">Tonga</option>
                                        <option value="TT">Trinidad and Tobago</option>
                                        <option value="TN">Tunisia</option>
                                        <option value="TR">Turkey</option>
                                        <option value="TM">Turkmenistan</option>
                                        <option value="TV">Tuvalu</option>
                                        <option value="UG">Uganda</option>
                                        <option value="UA">Ukraine</option>
                                        <option value="AE">United Arab Emirates</option>
                                        <option value="GB">United Kingdom</option>
                                        <option value="US">United States</option>
                                        <option value="UY">Uruguay</option>
                                        <option value="UZ">Uzbekistan</option>
                                        <option value="VU">Vanuatu</option>
                                        <option value="VE">Venezuela</option>
                                        <option value="VN">Vietnam</option>
                                        <option value="YE">Yemen</option>
                                        <option value="ZM">Zambia</option>
                                        <option value="ZW">Zimbabwe</option>

                                    </Form.Select>
                                    <Form.Check
                                        className="mt-3"
                                        type="checkbox"
                                        id="registerAcceptTerms"
                                        label="I accept the Terms of Service."
                                        required
                                    />
                                </Form.Group>

                                <div className="d-grid">
                                    <Button type="submit" disabled={isProcessing} className="btn btn-centhost">
                                        {isProcessing ? <Spinner animation="border" size="sm" /> : "Create Account"}
                                    </Button>
                                </div>
                            </Form>

                            <ul className="mt-4">
                                <li>
                                    <a href="/login">
                                        <FontAwesomeIcon className="me-2" icon={faUserAlt} /> Already have an account? Sign in
                                    </a>
                                </li>
                                <li>
                                    <a href="https://centnodes.com">
                                        <FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} /> Back to homepage
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Register;
