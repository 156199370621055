import { useEffect, useState } from "react";
import './Checkout.css';
import Navigation from '../../components/Navigation/Navigation';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import productService from "../../services/product.service";
import orderService from "../../services/order.service";
import userService from "../../services/user.service";

function Checkout() {
    const [cartItem, setCartItem] = useState([]);
    const [checkOutUrl, setCheckOutUrl] = useState(null);
    const [hideOrderDetails, setHideOrderDetails] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(true);
    const [paymentFailed, setPaymentFailed] = useState(true);
    const [processPayment, setProcessPayment] = useState(true);
    const [totalPrice, setTotalPrice] = useState(0);
    const [userDetails, setUserDetails] = useState({});
    const [planDetails, setPlanDetails] = useState(null);
    const [orderDetails, setOrderDetails] = useState(null);
    const [disablePayButton, setDisablePayButton] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState('stripe');
    const [checkOutError, setCheckOutError] = useState(null);
    const [couponCode, setCouponCode] = useState(null);
    const [maximumPackageError, setMaximumPackagesError] = useState(false);
    const applyCoupon = () => {
        console.log(couponCode);
        return
    }

    const getServerPlanDetails = async (planId) => {
        try {
            const { data: serverPlans } = await productService.getProducts();
            const planDetail = serverPlans.find(x => x.id === planId);
            const orderDetails = JSON.parse(localStorage.getItem('temp_order_details'));

            if (!planDetail) {
                throw new Error('Plan details not found.');
            }

            const discountedPrice =
                planDetail.discount_deal === 'Black Friday Deal' ? 4.99 : planDetail.price;

            setPlanDetails({ ...planDetail, discountedPrice });
            setOrderDetails(orderDetails[0]);

            if (orderDetails[0].productType === 'server') {
                calculateTotalServer(discountedPrice, orderDetails[0].features);
            } else {
                calculateTotalHosting(discountedPrice);
            }

            setDisablePayButton(false);
        } catch (error) {
            console.error('Error fetching server plan details:', error);
        }
    };

    const getBillingInfo = async () => {
        try {
            const { data: user } = await userService.getUser();
            setUserDetails(user);
        } catch (error) {
            console.error('Error fetching billing info:', error);
        }
    };

    const calculateTotalHosting = (price) => {
        const total = parseFloat(price);
        const tax = total * 0.21;
        const totalAndTax = total + tax;
        setTotalPrice(totalAndTax.toFixed(2));
    };

    const calculateTotalServer = (price, featureDetails) => {
        let total = parseFloat(price);
        const originalPrice = total;

        if (featureDetails.ddos) {
            total += originalPrice * 0.10;
        }

        if (featureDetails.backup) {
            total += originalPrice * 0.20;
        }

        const tax = total * 0.21;
        const totalAndTax = total + tax;
        setTotalPrice(totalAndTax.toFixed(2));
    };

    const updatePaymentMethod = (event) => {
        const selectedPaymentMethod = event.target.value;
        setPaymentMethod(selectedPaymentMethod);
    };

    const checkOut = async () => {
        document.getElementById('purchaseButton').setAttribute('disabled', true);
        try {
            setCheckOutError(null);
            cartItem[0].paymentMethod = 'stripe';
            cartItem[0].couponCode = couponCode ? couponCode : null;


            let createOrder;

            if (cartItem[0].productType === "server") {
                createOrder = await orderService.createCloudOrder(cartItem[0]);
            } else if (cartItem[0].productType === "hosting") {
                createOrder = await orderService.createHostingOrder(cartItem[0]);
            } else if (cartItem[0].productType === "gaming") {
                createOrder = await orderService.createGamingOrder(cartItem[0]);
            }

            console.log(createOrder)

            if (createOrder.message == 'User already has a free package for this type.') {
                setMaximumPackagesError(true);
                return;
            }

            setCheckOutUrl(createOrder.data.payment_url);
            setHideOrderDetails(true);
            setProcessPayment(false);
            setOrderId(createOrder.data.order_id);

            window.open(createOrder.data.payment_url, "_blank");

            for (let i = 0; i < 35; i++) {
                setTimeout(async () => {
                    const order = await orderService.getOrder(createOrder.data.order_id);
                    if (order.data.status === 'active') {
                        setPaymentSuccess(false);
                        setProcessPayment(true);
                        return;
                    }
                    if (['invalid', 'canceled'].includes(order.data.status)) {
                        setPaymentFailed(false);
                        setProcessPayment(true);
                    }
                }, 1000 + (5000 * i));
            }
        } catch (err) {
            console.error('Error creating order:', err);
            setCheckOutError(true);
        }
    };

    useEffect(() => {
        const orderDetails = JSON.parse(localStorage.getItem('temp_order_details'));
        setCartItem(orderDetails);
        getServerPlanDetails(orderDetails[0].planId);
        getBillingInfo();
    }, []);

    return (
        <div className="content">
            <Navigation />
            <div id="main" className="container px-3 mt-5">
                <h4 className="mt-3 page-title">Checkout</h4>
                <h6 className="mt-1 breadcrumb">
                    <a className="breadcrumb" href="/dashboard">Dashboard</a> / Checkout
                </h6>
                <div id="processingPayment" className="row mt-4" hidden={processPayment}>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body p-5 text-center">
                                <h5 className="color-primary mt-2">Processing your payment</h5>
                                <p>Please pay in the next tab.</p>
                                <p>Your order ID: {orderId || 'Error. Please contact support'}</p>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <div className="row">
                                    <a href={checkOutUrl} target="_blank" className="mt-5 btn btn-centhost d-block">
                                        Pay
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="paymentFailed" className="row mt-4" hidden={paymentFailed}>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body p-5 text-center">
                                <h5 className="color-primary mt-2">Payment Status</h5>
                                <h4>Payment failed!</h4>
                                <FontAwesomeIcon className="p-5" icon={faCircleXmark} color="red" size="10x" />
                                <p>Please check your email <span className="fw-600">{localStorage.getItem('email') || 'error'}</span> for details about your purchase</p>
                                <div className="mt-5 text-center">
                                    <a href="/help" className="btn btn-centhost">Support</a>
                                    <a href="/dashboard" className="mx-3 btn btn-centhost">My Products</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="paymentSuccess" className="row mt-4" hidden={paymentSuccess}>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="color-primary mt-2 text-center">
                                <h5 className="card-title">Payment Status</h5>
                                <h4>Payment successful!</h4>
                                <FontAwesomeIcon className="p-5" icon={faCheckDouble} color="lightgreen" size="10x" />
                                <p>Please check your email <span className="fw-600">{localStorage.getItem('email') || 'error'}</span> for details about your purchase</p>
                                <div className="mt-5 text-center">
                                    <a href="/invoices" className="btn btn-centhost">Invoices</a>
                                    <a href="/dashboard" className="mx-3 btn btn-centhost">Dashboard</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="orderDetails" className="row mt-4" hidden={hideOrderDetails}>
                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-body p-4">
                                <h5 className="color-primary mt-2">Billing Details</h5>
                                <form>
                                    <div className="row g-3 mb-3">
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="firstName" className="form-label">First Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="firstName"
                                                disabled
                                                required
                                                value={userDetails.firstname || 'loading...'}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="lastName" className="form-label">Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="lastName"
                                                disabled
                                                required
                                                value={userDetails.lastname || 'loading...'}
                                            />
                                        </div>
                                    </div>
                                    <div className="row g-3 mb-3">
                                        <div className="col-12 col-md-8">
                                            <label htmlFor="street" className="form-label">Street</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="street"
                                                disabled
                                                required
                                                value={userDetails.street || 'loading...'}
                                            />
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <label htmlFor="streetNumber" className="form-label">Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="streetNumber"
                                                disabled
                                                required
                                                value={userDetails.street_number || 'loading...'}
                                            />
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <label htmlFor="country" className="form-label">Country</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="country"
                                                disabled
                                                required
                                                value={userDetails.country || 'loading...'}
                                            />
                                        </div>
                                    </div>
                                    <div className="row g-3">
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="city" className="form-label">City</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="city"
                                                disabled
                                                required
                                                value={userDetails.city || 'loading...'}
                                            />
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <label htmlFor="state" className="form-label">State</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="state"
                                                disabled
                                                required
                                                value={userDetails.state || 'loading...'}
                                            />
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <label htmlFor="zip" className="form-label">Zip</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="zip"
                                                disabled
                                                required
                                                value={userDetails.zip || 'loading...'}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-body p-4">
                                <h5 className="color-primary mt-2">Order Details</h5>
                                {cartItem[0] && cartItem[0].productType === "server" && (
                                    <ul className="list-unstyled">
                                        <li><strong>Server Label:</strong> {cartItem[0].serverLabel}</li>
                                        <li><strong>Location:</strong> {cartItem[0].location}</li>
                                        <li><strong>Operating system:</strong> {cartItem[0].osName}</li>
                                        <li><strong>Hostname:</strong> {cartItem[0].hostname}</li>
                                        <li><strong>Plan:</strong> {planDetails && planDetails.name}</li>
                                        {orderDetails && orderDetails.productType == 'server' && (<div>
                                            {orderDetails.features.support && (<li><strong>Feature:</strong> Support Premium</li>)}
                                            {orderDetails.features.backup && (<li><strong>Feature:</strong> Backups</li>)}
                                            {orderDetails.features.ddos && (<li><strong>Feature:</strong> DDoS protection</li>)}
                                        </div>
                                        )}
                                    </ul>
                                )}
                                {cartItem[0] && cartItem[0].productType === "hosting" && (
                                    <ul className="list-unstyled">
                                        <li><strong>Plan:</strong> {planDetails && planDetails.name}</li>
                                        <li><strong>Label:</strong> {cartItem[0].hostingLabel}</li>
                                    </ul>
                                )}
                                {cartItem[0] && cartItem[0].productType === "gaming" && (
                                    <ul className="list-unstyled">
                                        <li><strong>Plan:</strong> {planDetails && planDetails.name}</li>
                                        <li><strong>Label:</strong> {cartItem[0].serverLabel}</li>
                                    </ul>
                                )}
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-body">
                                {checkOutError && <div className="alert alert-danger"><strong>Error:</strong> Something went wrong creating payment.</div>}
                                {maximumPackageError && <div className="alert alert-danger"><strong>Error:</strong> You can only have one free package of each hosting type.</div>}

                                <div className="paymentMethods">

                                    <h5 className="color-primary mt-2">Payment Method</h5>
                                    <select onChange={updatePaymentMethod} value={paymentMethod} className="form-control p-2">
                                        <option value="stripe">Credit Card</option>
                                        <option value="gpay">Google Pay</option>
                                        <option value="ban">Bancontact</option>
                                    </select>
                                    {/* <h5 className="color-primary mt-2">Coupon Code</h5>
                                    <input name="coupon" value={couponCode} className="form-control" onChange={(e) => setCouponCode(e.target.value)} />
                                    <button onClick={applyCoupon} className="btn-centhost m-1">Apply</button> */}
                                </div>
                                <hr />
                                <div className="purchaseBox p-2">
                                    <h6 className="fw-normal">
                                        <strong>Package:</strong>
                                        {planDetails && planDetails.discount_deal === 'Black Friday Deal' ? (
                                            <span>
                                                <span
                                                    style={{
                                                        textDecoration: 'line-through',
                                                        color: 'gray',
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    €{parseFloat(planDetails.price).toFixed(2)}
                                                </span>
                                                <span style={{ fontWeight: 'bold', color: '#d9534f' }}>
                                                    €{parseFloat(4.99).toFixed(2)}
                                                </span>
                                            </span>
                                        ) : (
                                            <span>€{planDetails && parseFloat(planDetails.price).toFixed(2)}</span>
                                        )}
                                    </h6>
                                    {orderDetails && orderDetails.productType == 'server' && (
                                        <div>

                                            <h6 className="fw-normal"><strong>Feature: Backups:</strong> €{orderDetails && orderDetails.productType == 'server' && orderDetails.features.backup ? (parseFloat(planDetails.price) * 0.20).toFixed(2) : '0.00'}</h6>
                                            <h6 className="fw-normal"><strong>Feature: DDoS Protection:</strong> €{orderDetails & orderDetails.productType == 'server' && orderDetails.features.ddos ? (parseFloat(planDetails.price) * 0.10).toFixed(2) : '0.00'}</h6>

                                        </div>
                                    )}
                                    <h6 className="fw-normal"><strong>Tax (VAT) 21%:</strong> €{planDetails && (totalPrice - (totalPrice / 1.21)).toFixed(2)}</h6>
                                    <h6 className="fw-normal"><strong>Total:</strong> €{totalPrice}</h6>
                                    <button id="purchaseButton" disabled={disablePayButton} onClick={checkOut} className="btn-lg w-100 btn-centhost mt-2">Pay</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Checkout;
