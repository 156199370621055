import axios from "axios";

const apiUrl = (url) => {
    let res;
    switch (true) {
        case url.includes('localhost:3000'):
            res = 'http://localhost:8000/api'
            break;
        case url.includes('dev.centnodes.com'):
            res = 'https://api-dev.centnodes.com/api'
            break;
        default:
            res = 'https://api.centnodes.com/api'
            break;
    }
    return res;
}

const API_URL = apiUrl(window.location.href);

const login = async (credentials) => {
    try {
        const login = await axios.post(API_URL + '/auth/login', { email: credentials.email, password: credentials.password, rememberMe: credentials.rememberMe, turnstile_token: credentials.turnstileToken })

        return login.data
    } catch (err) {
        return;
    }
}

const register = async (user) => {
    try {
        const register = await axios.post(API_URL + '/auth/register', user)

        return register;
    } catch (err) {
        if (err.response.data.errors.email[0]) {
            return 'email taken';
        }
        console.log(err);
        return false;
    }
}
const logout = async () => {
    localStorage.clear();
    window.location.assign('/logout')
    return;
}

const checkTwoFactor = async (id, code) => {
    try {
        const twofactorCheck = await axios.post(API_URL + '/auth/twofactor/check', { id: id, code: code })

        return twofactorCheck.data
    } catch (err) {
        return;
    }
}

export default { register, login, logout, checkTwoFactor }