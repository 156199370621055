import React, { useState } from "react";
import useTable from "../../hooks/useTable";
import "./CloudTable.css";
import "./Table.css";
import TableFooter from "./TableFooter";
import RegionFlag from "../RegionFlag/RegionFlag";
import OSimage from "../OSimage/OSimage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";

const CloudTable = ({ data, rowsPerPage, refreshFunction, pagination = true, disableHeader = false }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);

    return (
        <div className="serverTable">
            {!disableHeader && (
                <div className="row justify-content-start d-flex">
                    <div className="col-1 d-md-block d-none">
                        <p className="fw-bold m-0">Status</p>
                    </div>
                    <div className="col-3 d-md-block d-none">
                        <p className="fw-bold m-0">Label</p>
                    </div>
                    <div className="col-3 d-md-block d-none">
                        <p className="fw-bold m-0">Region</p>
                    </div>
                    <div className="col-3 d-md-block d-none">
                        <p className="fw-bold m-0">OS</p>
                    </div>
                    <div className="col-1 d-none d-md-block">
                    </div>
                </div>
            )}

            {slice.map((server) => (
                <div
                    onClick={() => {
                        if (!['cancelled', 'pending', 'failed'].includes(server.status)) {
                            window.location.assign('/products/cloud/' + server.id);
                        }
                    }}
                    key={server.id}
                     className="row serverRow justify-content-start d-flex my-2 p-3"
                >
                    <div className="col-1">
                        {server.status === 'active' && <Badge bg="success"> </Badge>}
                        {server.status === 'suspended' && <Badge bg="secondary"> </Badge>}
                        {server.status === 'installing' && <Badge bg="warning"> </Badge>}
                        {server.status === 'cancelled' && <Badge bg="secondary"> </Badge>}
                        {server.status === "failed" && <Badge bg="danger"> </Badge>}
                    </div>
                    <div className="col-4 col-sm-3">
                        <p className="m-0 p-0">{server.label}</p>
                        <small className="m-0 p-0 d-none d-sm-flex">{server.ip}</small>
                    </div>
                    <div className="col-3 col-sm-3">
                        <RegionFlag height={"20px"} regionId={server.region} />
                    </div>
                    <div className="col-2 col-sm-3">
                        <OSimage osId={server.os_id} height={"40px"} />
                    </div>
                    <div className="col-1 d-none d-md-block">
                        <p className="mt-3">
                            <FontAwesomeIcon icon={faArrowRight} />
                        </p>
                    </div>
                </div>
            ))}

            {pagination && <TableFooter range={range} slice={slice} setPage={setPage} page={page} />}
        </div>
    );
};

export default CloudTable;
