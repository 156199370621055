import { useEffect, useState, forwardRef } from "react";
import './Navigation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faArrowRightFromBracket, faBars, faBell, faClock, faFileInvoice, faGear, faHome, faMicrochip, faServer, faHeadset, faGamepad } from '@fortawesome/free-solid-svg-icons'
import logo_dark from '../../images/logo_dark.png';
import logo_white from '../../images/logo_white.png';
import { faProductHunt } from "@fortawesome/free-brands-svg-icons";
import { Alert } from "react-bootstrap";

const Navigation = forwardRef((props, ref) => {


    const [sideBar, setSidebar] = useState(false);
    const [userDropDown, setUserDropDown] = useState(false);
    const [isAdmin, setAdmin] = useState(false);
    const [basketDropDown, setBasketDropDown] = useState(false);


    const darkModeStatus = true;
    const darkModeBtn = document.querySelector("#dark-mode");



    function toggleMenu() {
        if (sideBar === true) {
            document.body.classList.remove('toggle-sidebar')
            setSidebar(false)
        } else {
            document.body.classList.add('toggle-sidebar')

            setSidebar(true)

        }
    }


    const Logout = () => {
        window.location.assign('/logout');
    }

    function toggleUserInfo() {
        let menu = document.getElementById('userDropDown');
        if (userDropDown === true) {
            menu.classList.remove('show')
            setUserDropDown(false)
        } else {
            menu.classList.add('show')
            setUserDropDown(true)
        }
    }

    async function toggleBasketDropDown() {
        let menu = document.getElementById('basketDropDown');
        if (basketDropDown === true) {
            menu.classList.remove('show')
            setBasketDropDown(false)
        } else {
            menu.classList.add('show')
            setBasketDropDown(true)
        }
    }

    function darkMode(isEnabled) {

        const logosDark = document.querySelectorAll(".logo-dark");
        const logosLight = document.querySelectorAll(".logo-light");
        const darkModeTxt = (darkModeBtn) ? darkModeBtn.querySelector("span") : false;

        if (isEnabled == true) {
            document.body.setAttribute("data-cc-theme", "dark");
            sessionStorage.setItem('darkMode', true)
            if (darkModeTxt) darkModeTxt.innerHTML = 'Light Mode';
            if (logosDark.length >= 1 && logosLight.length >= 1) {
                logosLight.forEach(logo => logo.classList.remove("d-none"));
                logosDark.forEach(logo => logo.classList.add("d-none"));
            };

        } else {
            document.body.removeAttribute("data-cc-theme");
            sessionStorage.removeItem('darkMode')
            if (darkModeTxt) darkModeTxt.innerHTML = 'Light Mode';
            if (logosDark.length >= 1 && logosLight.length >= 1) {
                logosLight.forEach(logo => logo.classList.add("d-none"));
                logosDark.forEach(logo => logo.classList.remove("d-none"));
            };
        }

    };

    const darkModeToggler = () => {
        if (sessionStorage.getItem('darkMode')) {
            darkMode(false)

        } else {
            darkMode(true)
        }
        return
    }

    const darkModeCheck = () => {
        if (sessionStorage.getItem('darkMode')) {
            darkMode(true)

        } else {
            darkMode(false)
        }
        return
    }

    useEffect(() => {
        darkModeCheck()

        if (localStorage.getItem('role') === 'Administrator') {
            setAdmin(true)
        } else {
            setAdmin(false)
        }
        let xad = localStorage.getItem('xad');
        if (!xad) {
            window.location.href = '/login/2fa';
        }


    }, [])


    return (
        <>
            <button id="dark-mode" onClick={darkModeToggler} className="btn"><FontAwesomeIcon icon={faMoon} /><span className="ms-2">Dark Mode</span></button>
            <div id="dashboardNavigation" className="header fixed-top-dashboard d-flex align-items-center">
                <div className="d-flex align-items-center justify-content-between">

                    <a href="/dashboard" className="navbar-brand w-100 logo logo-dark"><img src={logo_dark} alt="company logo dark" /></a>
                    <a href="/dashboard" className="navbar-brand w-100 logo logo-light d-none"><img src={logo_white} alt="company logo light" /></a>
                    <p className="mt-3 ms-md-3 p-2" onClick={toggleMenu}><FontAwesomeIcon size={'xl'} icon={faBars} /></p>


                </div>

                <nav className="mt-2 ms-auto me-md-5">
                    <ul className="d-flex align-items-center">

                        {localStorage.getItem('role') && localStorage.getItem('role') == 'imposter' && <p>!! Imposter !! </p>}


                        <li className="nav-item dropdown">
                            <a className="dropdown-toggle d-inline font-weight-bold" onClick={toggleUserInfo} id="userDropDownLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img className="mt-2 avatar img-fluid rounded-circle d-inline me-2" src={localStorage.getItem('avatar')} />
                                <p className="d-none d-md-inline usernameNav">{localStorage.getItem('firstname') + ' ' + localStorage.getItem('lastname')}</p>
                            </a>
                            <ul className="dropdown-menu p-2" id="userDropDown" aria-labelledby="userDropDown">

                                <a className="nav-link" href="/settings"><li>Settings</li></a>
                                <a className="nav-link" target="_blank" href="https://centnodes.com/help"><li>Help</li></a>
                                <a className="nav-link" href="/logout" onClick={() => Logout}><li>Sign Out</li></a>

                            </ul>
                        </li>
                    </ul>
                </nav>

            </div>

            <aside id="sidebar" className="sidebar">
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="/dashboard">
                            <FontAwesomeIcon color={'#2f57a4'} className="me-2" icon={faHome} />
                            <span>Dashboard</span></a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link collapsed" href="/products/clouds">
                            <FontAwesomeIcon color={'#2f57a4'} className="me-2" icon={faMicrochip} />
                            <span>Cloud Hosting</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="/products/hosting">
                            <FontAwesomeIcon color={'#2f57a4'} className="me-2" icon={faServer} />
                            <span>Web hosting</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="/products/gaming">
                            <FontAwesomeIcon color={'#2f57a4'} className="me-2" icon={faGamepad} />
                            <span>Game Hosting</span>
                        </a>
                    </li>
                    <li className="nav-heading"><hr></hr></li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="/invoices">
                            <FontAwesomeIcon color={'#2f57a4'} className="me-2" icon={faFileInvoice} />
                            <span>Billing</span>
                        </a>
                    </li>

                    {isAdmin ?
                        (<div className="d-inline">
                            <li className="nav-heading">Administrator</li>
                            <li className="nav-item">
                                <a className="nav-link collapsed" href="/admin/orders">
                                    <FontAwesomeIcon color={'#2f57a4'} className="me-2" icon={faClock} />
                                    <span>Orders (0)</span>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link collapsed" href="/admin/products">
                                    <FontAwesomeIcon color={'#2f57a4'} className="me-2" icon={faProductHunt} />
                                    <span>Products</span>
                                </a>
                            </li>
                        </div>)

                        : null}

                    <div className="  position-absolute bottom-0 pb-3 ">

                        <li className="nav-item">
                            <a className="nav-link collapsed" href="/tickets">
                                <FontAwesomeIcon color={'#2f57a4'} className="me-2" icon={faHeadset} />
                                <span>Support</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link collapsed" href="/settings">
                                <FontAwesomeIcon color={'#2f57a4'} className="me-2" icon={faGear} />
                                <span>Settings</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a onClick={() => Logout()} className="nav-link collapsed" href="/logout">
                                <FontAwesomeIcon color={'#2f57a4'} className="me-2" icon={faArrowRightFromBracket} />
                                <span>Sign Out</span>
                            </a>
                        </li>
                    </div>
                </ul>
            </aside>

        </>
    )
})
export default Navigation;