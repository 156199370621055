import axios from "axios";
import authService from "./auth.service";

const apiUrl = (url) => {
    let res;
    switch (true) {
        case url.includes('localhost:3000'):
            res = 'http://localhost:8000/api'
            break;
        case url.includes('dev.centnodes.com'):
            res = 'https://api-dev.centnodes.com/api'
            break;
        default:
            res = 'https://api.centnodes.com/api'
            break;
    }
    return res;
}

const API_URL = apiUrl(window.location.href);
const token = localStorage.getItem('token')
const config = { headers: { 'Authorization': 'Bearer ' + token } }

const getOrders = async () => {
    try {
        const orders = await axios.get(API_URL + '/order', config)

        return orders.data
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const getOrder = async (id) => {
    try {
        const order = await axios.get(API_URL + '/order/' + id, config)

        return order.data
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }

    }
}

const createServerOrder = async (orderDetails) => {

    try {
        const createOrder = await axios.post(API_URL + '/order/create/server', orderDetails, config)

        return createOrder.data

    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const createGamingOrder = async (orderDetails) => {

    try {
        const createOrder = await axios.post(API_URL + '/order/create/gaming', orderDetails, config)

        return createOrder.data

    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const createHostingOrder = async (orderDetails) => {

    try {
        const createOrder = await axios.post(API_URL + '/order/create/hosting', orderDetails, config)

        return createOrder.data

    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const createCloudOrder = async (orderDetails) => {

    try {
        const createOrder = await axios.post(API_URL + '/order/create/server', orderDetails, config)


        return createOrder.data

    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}


export default { createCloudOrder, getOrders, getOrder, createServerOrder, createHostingOrder, createGamingOrder }