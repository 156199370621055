import { useEffect, useState } from "react";
import './DeployCloud.css';
import Navigation from '../../../components/Navigation/Navigation';
import nlFlag from '../../../images/flags/flag_nl.png';
import productService from "../../../services/product.service";
import deFlag from '../../../images/flags/flag_de.png';

const DeployCloud = () => {
    const [plans, setPlans] = useState([]);

    const mockOSList = [
        { id: 101, name: 'Debian 12', family: 'debian' },
        // { id: 2, name: 'Windows Server 2019', family: 'windows' },
    ];

    const mockLocations = {
        amsterdam: {
            name: "Amsterdam",
            country: "Netherlands",
            flag: nlFlag
        },
        germany: {
            name: "Dusseldorf",
            country: "Germany",
            flag: deFlag
        }
    };

    const [data, setData] = useState({
        osList: mockOSList,
        plans: plans,
        availablePlans: [],
        selectedPlanId: null,
        serverOS: null,
        serverLocation: null,
        selectedOSName: '',
        featureBackup: false,
        featureDDOS: false,
        featureSupport: false,
        serverHostname: '',
        serverLabel: '',
        backupPrice: 0,
        ddosPrice: 0,
        supportPrice: 0,
        checkoutDisabled: true,
        checkoutError: false,
        hostnameError: '',
        labelError: '',
    });

    const hostnameRegex = /^[a-zA-Z0-9-]{1,63}$/;

    const calculateAdditionalCosts = (planPrice) => {
        setData((prevData) => ({
            ...prevData,
            backupPrice: (planPrice * 0.20).toFixed(2),
            ddosPrice: (planPrice * 0.10).toFixed(2),
            supportPrice: planPrice >= 15 ? (planPrice * 0.25).toFixed(2) : 0,
        }));
    };

    const updateOS = (osId) => {
        const selectedOS = data.osList.find(os => os.id == osId);
        setData((prevData) => ({
            ...prevData,
            serverOS: osId,
            selectedOSName: selectedOS ? selectedOS.name : '',
            availablePlans: [],
            selectedPlanId: null,
            serverLocation: null,
            checkoutDisabled: true,
        }));
    };

    const updateServerLocation = (location) => {
        setData((prevData) => ({
            ...prevData,
            serverLocation: location,
            availablePlans: plans,
            selectedPlanId: null,
            checkoutDisabled: true,
        }));
    };

    const createOrder = () => {
        const { serverOS, serverLabel, serverHostname, serverLocation, selectedPlanId, featureDDOS, featureBackup, featureSupport } = data;

        if (!serverOS || !serverLabel || !serverHostname) {
            setData((prevData) => ({ ...prevData, checkoutError: true }));
            return;
        }

        if (serverHostname.length < 4 || serverLabel.length < 4) {
            setData((prevData) => ({ ...prevData, checkoutError: true }));
            return;
        }

        setData((prevData) => ({ ...prevData, checkoutError: false }));

        const selectedPlan = plans.find(plan => plan.id === selectedPlanId);

        const parsedSpecs = JSON.parse(selectedPlan.specifications);

        const serverDetails = [{
            osId: serverOS,
            osName: data.selectedOSName,
            serverLabel: serverLabel,
            hostname: serverHostname,
            location: serverLocation,
            productType: 'server',
            planId: selectedPlanId,
            features: {
                ddos: featureDDOS,
                backup: featureBackup,
                support: featureSupport
            },
            specs: {
                ram: parseInt(parsedSpecs.memory, 10),
                cpu: parseInt(parsedSpecs.cpu, 10),
                disk: parseInt(parsedSpecs.disk, 1000)
            }
        }];


        localStorage.setItem('temp_order_details', JSON.stringify(serverDetails));
        window.location.href = '/checkout';
    };

    const updateSelectedPlan = (planId) => {
        const selectedPlan = plans.find(plan => plan.id === planId);
        if (selectedPlan) {
            setData((prevData) => ({
                ...prevData,
                selectedPlanId: planId,
                checkoutDisabled: !(data.serverHostname.length >= 4 && data.serverLabel.length >= 4),
            }));
            calculateAdditionalCosts(parseFloat(selectedPlan.price));
        } else {
            setData((prevData) => ({ ...prevData, checkoutDisabled: true }));
        }
    };

    const handleHostnameChange = (event) => {
        const value = event.target.value;
        setData((prevData) => ({
            ...prevData,
            serverHostname: value,
            hostnameError: !hostnameRegex.test(value) ? 'Invalid hostname' : '',
            checkoutDisabled: !(value.length >= 4 && data.serverLabel.length >= 4),
        }));
    };

    const handleLabelChange = (event) => {
        const value = event.target.value;
        setData((prevData) => ({
            ...prevData,
            serverLabel: value,
            labelError: value.length < 4 ? 'Label must be at least 4 characters' : '',
            checkoutDisabled: !(value.length >= 4 && data.serverHostname.length >= 4),
        }));
    };

    const getPlans = async () => {
        let plans = await productService.getProductsByType('server');
        plans = plans.data.filter((plan) => plan.status !== 'inactive');

        setPlans(plans);
    }

    useEffect(() => {
        getPlans()
        localStorage.removeItem('temp_order_details');
    }, []);

    const groupedOS = data.osList.reduce((acc, osItem) => {
        if (!acc[osItem.family]) {
            acc[osItem.family] = [];
        }
        acc[osItem.family].push(osItem);
        return acc;
    }, {});

    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Deploy a Cloud VPS</h4>
                <h6 className="mt-1 breadcrumb">
                    <a className="breadcrumb" href="/dashboard">Dashboard</a> /
                    <a className="breadcrumb" href="/products/clouds"> Cloud Hosting</a> / Deploy a Cloud VPS
                </h6>
                <div className="row mt-4">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <h3 className="color-primary mt-2 text-center">Operating System</h3>
                                    {Object.keys(groupedOS).length ? (
                                        <div className="row">
                                            {Object.keys(groupedOS).map(family => (
                                                <div key={family} className="col-12 col-sm-6 col-md-6">
                                                    <div className={`osFamilyCard ${data.serverOS && groupedOS[family].some(osItem => osItem.id === data.serverOS) ? 'selected' : ''}`}>
                                                        <div className="osFamilyItem">
                                                            <img src={require(`../../../images/os/icon-${family}.png`)} className="img-fluid osFamilyImage" alt={family} />
                                                            <div className="osFamilyDetails">
                                                                <h5>{family}</h5>
                                                                <select
                                                                    value={data.serverOS}
                                                                    onChange={(e) => updateOS(e.target.value)}
                                                                    className="mt-2 form-select"
                                                                >
                                                                    <option value="">Select {family}</option>
                                                                    {groupedOS[family].map(osItem => (
                                                                        <option key={osItem.id} value={osItem.id}>{osItem.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="text-center"><div className="spinner-border text-primary" role="status"></div></div>
                                    )}
                                </div>

                                {data.serverOS && (
                                    <ServerLocationSelector updateServerLocation={updateServerLocation} selectedLocation={data.serverLocation} locations={mockLocations} />
                                )}

                                {data.serverLocation && (
                                    <div className="row mt-5 justify-content-center packageCards">
                                        <h3 className="color-primary mt-2 text-center">Select Package</h3>
                                        <div className="row">
                                            {data.availablePlans.map(plan => {
                                                const specs = JSON.parse(plan.specifications);
                                                return (
                                                    <div
                                                        key={plan.id}
                                                        className={`col-md-4 col-sm-6 mb-4 cursor-pointer ${data.selectedPlanId === plan.id ? 'selectedPackage' : ''}`}
                                                        onClick={() => updateSelectedPlan(plan.id)}
                                                    >
                                                        <div className="card shadow-sm">
                                                            <div className="card-body text-center">
                                                                <h5 className="card-title fw-bold">{plan.name}</h5>
                                                                <ul className="list-unstyled mb-3">
                                                                    <li>
                                                                        <strong>RAM:</strong> {(specs.memory / 1000).toFixed(0)} GB
                                                                    </li>
                                                                    <li>
                                                                        <strong>CPU:</strong> {specs.cpu} vCores
                                                                    </li>
                                                                    <li>
                                                                        <strong>Disk Space:</strong> {(specs.disk).toFixed(2)} GB SSD
                                                                    </li>
                                                                </ul>
                                                                <div>
                                                                    <span className="price">€{plan.price}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}

                                {data.selectedPlanId && (
                                    <div className="mt-5">
                                        <h3 className="color-primary text-center">Additional Features</h3>
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="featureDDOS" onChange={() => setData(prevData => ({ ...prevData, featureDDOS: !prevData.featureDDOS }))} checked={data.featureDDOS} />
                                                    <label className="form-check-label" htmlFor="featureDDOS">
                                                        Enable DDOS Protection + €{data.ddosPrice} (10% of plan price)
                                                    </label>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="featureBackup" onChange={() => setData(prevData => ({ ...prevData, featureBackup: !prevData.featureBackup }))} checked={data.featureBackup} />
                                                    <label className="form-check-label" htmlFor="featureBackup">
                                                        Enable Backups + €{data.backupPrice} (20% of plan price)
                                                    </label>
                                                </div>
                                            </li>
                                            {parseFloat(data.plans.find(x => x.id === data.selectedPlanId)?.price) >= 15 && (
                                                <li className="list-group-item">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="featureSupport" onChange={() => setData(prevData => ({ ...prevData, featureSupport: !prevData.featureSupport }))} checked={data.featureSupport} />
                                                        <label className="form-check-label" htmlFor="featureSupport">
                                                            Support Premium + €{data.supportPrice} (25% of plan price)
                                                        </label>
                                                    </div>
                                                    <ul>
                                                        <li>Fast response time</li>
                                                        <li>Dedicated communications channel</li>
                                                    </ul>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                )}

                                <HostnameLabelForm
                                    hostname={data.serverHostname}
                                    label={data.serverLabel}
                                    handleHostnameChange={handleHostnameChange}
                                    handleLabelChange={handleLabelChange}
                                    hostnameError={data.hostnameError}
                                    labelError={data.labelError}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="color-primary mt-2 text-center">Your configuration</h3>
                                {data.checkoutError && <div className="alert alert-danger" role="alert"><span className="fw-bold">Error:</span> Fill in required fields correctly.</div>}
                                {data.selectedPlanId && <p className="m-0"><span className="fw-bold">Package: </span> {data.availablePlans.find(x => x.id === data.selectedPlanId)?.name}</p>}
                                {data.serverOS && <p className="m-0"><span className="fw-bold">Operating System: </span> {data.selectedOSName}</p>}
                                {data.serverLocation && <p className="m-0"><span className="fw-bold">Region: </span> {mockLocations[data.serverLocation].name}, {mockLocations[data.serverLocation].country}</p>}
                                {data.featureBackup && <p className="m-0"><span className="fw-bold">Feature: </span> Backups + €{data.backupPrice}</p>}
                                {data.featureDDOS && <p className="m-0"><span className="fw-bold">Feature: </span> DDOS protection + €{data.ddosPrice}</p>}
                                {data.featureSupport && <p className="m-0"><span className="fw-bold">Feature: </span> Support Premium + €{data.supportPrice}</p>}

                                {data.selectedPlanId && (
                                    <p className="m-0">
                                        <span className="fw-bold">Price: </span>
                                        {data.availablePlans.find(x => x.id === data.selectedPlanId)?.discount_deal === 'Black Friday Deal' ? (
                                            <span>
                                                <span
                                                    style={{
                                                        textDecoration: 'line-through',
                                                        color: 'gray',
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    €{data.availablePlans.find(x => x.id === data.selectedPlanId)?.price}
                                                </span>
                                                <span style={{ fontWeight: 'bold', color: '#d9534f' }}>
                                                    €4.99
                                                </span>
                                            </span>
                                        ) : (
                                            <span>€{data.availablePlans.find(x => x.id === data.selectedPlanId)?.price}</span>
                                        )}
                                    </p>
                                )}
                                <button disabled={data.checkoutDisabled} id="createOrderButton" className="btn btn-primary w-100 mt-2" onClick={createOrder}>Go to Checkout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ServerLocationSelector = ({ updateServerLocation, selectedLocation, locations }) => (
    <div className="row mt-5 p-3 packageCards">
        <h3 className="color-primary mt-2 text-center">Select Location</h3>
        {Object.keys(locations).map(location => (
            <div
                key={location}
                className={`col-12 col-md-4 mb-3 ${selectedLocation === location ? 'selectedPackage' : ''} `}
                onClick={() => updateServerLocation(location)}
                style={{ cursor: 'pointer' }}
            >
                <div className={`card text-center card p-3 h-100 `}>
                    <img src={locations[location].flag} alt={`${locations[location].name} flag`} className="img-fluid mb-2" style={{ maxHeight: '60px', objectFit: 'contain' }} />
                    <p className="fw-bold mb-1">{locations[location].name}</p>
                    <p className="text-muted">{locations[location].country}</p>
                </div>
            </div>
        ))}
    </div>
);


const HostnameLabelForm = ({ hostname, label, handleHostnameChange, handleLabelChange, hostnameError, labelError }) => (
    <div className="row mt-5">
        <h3 className="color-primary text-center">Hostname & Label</h3>
        <div className="pb2">
            <div className="row">
                <div className="col-md-6">
                    <input
                        className="form-control"
                        type="text"
                        value={hostname}
                        onChange={handleHostnameChange}
                        placeholder="Server Hostname"
                    />
                    {hostnameError && <div className="invalid-feedback d-block">{hostnameError}</div>}
                </div>
                <div className="col-md-6">
                    <input
                        className="form-control"
                        type="text"
                        value={label}
                        onChange={handleLabelChange}
                        placeholder="Server Label"
                    />
                    {labelError && <div className="invalid-feedback d-block">{labelError}</div>}
                </div>
            </div>
        </div>
    </div>
);

export default DeployCloud;
