import { useEffect, useState } from "react";
import { Col, Row, Card, Button, Badge, Modal, Form, Alert } from "react-bootstrap";
import './Webhostings.css';
import Navigation from '../../../components/Navigation/Navigation'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, } from "@fortawesome/free-solid-svg-icons";
import HostingTable from "../../../components/Table/HostingTable";
import hostingService from "../../../services/hosting.service";
import productService from "../../../services/product.service";

export const Webhostings = () => {

    const [hostingPackages, setHostingPackages] = useState(null)

    const getPackages = async () => {
        let hostingPackagesData = await hostingService.getHostingPackages();
        let hostingPackagesFiltered = hostingPackagesData.data.filter(hostpack => hostpack.status !== 'pending')
        if (hostingPackagesFiltered) {
            setHostingPackages(hostingPackagesFiltered)
        }
    }


    useEffect(() => {
        getPackages()
    }, [])


    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Web Hosting</h4>
                <h6 className="mt-1 breadcrumb"><a className="breadcrumb" href="/dashboard">Dashboard</a> / Web Hosting</h6>
                <Row className="mt-4">
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <h6 className="d-flex justify-content-between">Web Hosting<div className="cardTitleButtons"><button onClick={() => window.location.assign('/products/hosting/deploy')} className="btn-sm btn-centhost"><FontAwesomeIcon icon={faPlus} /> Deploy</button></div></h6>
                                {hostingPackages && <HostingTable data={hostingPackages} rowsPerPage={5} refreshFunction={getPackages} pagination={true} />}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div >
        </div >
    )
}
