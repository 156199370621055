import React, { useEffect, useState } from 'react'
import './ConfirmationModal.css'
import { Button, Modal } from 'react-bootstrap'

export  const ConfirmationModal = (props) => {

    const [confirmText, setConfirmText] = useState(null)
    const [confirmTitle, setConfirmTitle] = useState(null)

 

    useEffect(() => {
        if (props.type === 'deleteAccount') {
            setConfirmTitle('Confirm account removal')
            setConfirmText('Are you sure you want to delete the account?')
        }
        if (props.type === 'instanceAction') {
            setConfirmTitle('Confirm server action.')
            setConfirmText('Are you sure you want to continue?')
        }
    }, [])


    return (
        <Modal backdrop="static" className="text-dark" show={props.isOpen} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title className="">{confirmTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='h6'>{confirmText}</p>
                <Button className="btn btn-centhost p-2" onClick={props.onHide}>Cancel</Button>
                <Button className="btn btn-centhost p-2" onClick={props.onConfirm}>Confirm</Button>
            </Modal.Body>
        </Modal>
    )
}