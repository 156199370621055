import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowsRotate, faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import './Cloud.css';
import Navigation from '../../../components/Navigation/Navigation';
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal.js";
import RegionFlag from "../../../components/RegionFlag/RegionFlag";
import { Spinner, Alert, ProgressBar } from "react-bootstrap";
import cloudService from "../../../services/cloud.service.js";

// Reusable ProgressBar component
const UsageProgress = ({ label, now, max }) => (
    <div>
        <div className="d-flex justify-content-between">
            <span className="text-white">{label}</span>
            <span className="text-white d-none d-lg-block">{now.toFixed(2)}{label == 'RAM Usage' ? 'MB' : '%'} / {max.toFixed(2)} {label == 'RAM Usage' ? 'MB' : '%'}</span>
        </div>
        <ProgressBar variant={'success'} now={(now / max) * 100} label={`${((now / max) * 100).toFixed(2)}%`} />
    </div>
);

export const Cloud = () => {
    const [cloud, setCloud] = useState(null);
    const [vmInfo, setVmInfo] = useState(null);
    const [backupBlock, setBackupBlock] = useState(true);
    const [cloudDetailsBlock, setCloudDetailsBlock] = useState(false);
    const [fireWallBlock, setFirewallBlock] = useState(true);
    const [recoveryBlock, setRecoveryBlock] = useState(true);
    const [destroyBlock, setDestroyBlock] = useState(true);
    const [osBlock, setOsBlock] = useState(true);
    const [networkBlock, setNetworkBlock] = useState(true);
    const [packageBlock, setPackageBlock] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [cloudBackups, setCloudBackups] = useState([])
    const [actionType, setActionType] = useState(null);
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedBackup, setSelectedBackup] = useState(null);

    const getCloud = async (instanceId) => {
        try {
            const response = await cloudService.getCloud(instanceId);
            setCloud(response.data.cloud);
            setVmInfo(response.data.vm_info);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching cloud details:", error);
            setLoading(false);
        }
    };

    const menuChange = async (item) => {
        setBackupBlock(true);
        setCloudDetailsBlock(true);
        setFirewallBlock(true);
        setRecoveryBlock(true);
        setDestroyBlock(true);
        setOsBlock(true);
        setNetworkBlock(true);
        setPackageBlock(true);

        switch (item) {
            case 'backup':
                setBackupBlock(false);
                const backups = await cloudService.getBackups(cloud.id);
                setCloudBackups(backups.data);
                console.log(backups);
                break;
            case 'firewall':
                setFirewallBlock(false);
                break;
            case 'recovery':
                setRecoveryBlock(false);
                break;
            case 'destroy':
                setDestroyBlock(false);
                break;
            case 'operating_system':
                setOsBlock(false);
                break;
            case 'network':
                setNetworkBlock(false);
                break;
            case 'package':
                setPackageBlock(false);
                break;
            default:
                setCloudDetailsBlock(false);
                break;
        }
    };

    const handleRestore = async (volid) => {
        try {
            setSelectedBackup(volid)
            const restore = await instanceAction('restoreBackup', false);

            if (!restore) {
                return;
            } else {
                console.log(restore);
            }
        } catch (error) {
            console.error("Failed to restore backup:", error);
        }
    };


    const hideConfirmationBox = () => {
        setShowConfirmation(false);
        setActionType(null);
    };

    const instanceAction = async (action, confirmed) => {
        if (!confirmed) {
            setShowConfirmation(true);
            setActionType(action);
            return;
        }

        try {
            let actionResponse;
            switch (action) {
                case 'start':
                    actionResponse = await cloudService.startCloud(cloud.id);
                    break;
                case 'stop':
                    actionResponse = await cloudService.stopCloud(cloud.id);
                    break;
                case 'reboot':
                    actionResponse = await cloudService.rebootCloud(cloud.id);
                    break;
                case 'restoreBackup':
                    actionResponse = await cloudService.restoreBackup(cloud.id, selectedBackup);
                    break;
                default:
                    throw new Error("Invalid action type");
            }

            hideConfirmationBox();
            setSuccessMessage(`Action ${action} successful.`);
            setTimeout(() => setSuccessMessage(null), 3000);
            setTimeout(() => getCloud(cloud.id), 500);
        } catch (error) {
            console.error(`Error performing action ${action}:`, error);
        }
    };

    useEffect(() => {
        const instanceId = window.location.href.split("/cloud").pop().replace('/', '');
        getCloud(instanceId);
    }, []);

    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Server Details</h4>
                <h6 className="mt-1 breadcrumb">
                    <a className="breadcrumb" href="/dashboard">Dashboard</a> /
                    <a className="breadcrumb" href="/products/clouds"> Cloud Hosting</a> /
                    {cloud && cloud.label.replace('centnodes-', '')}
                </h6>

                {successMessage && (
                    <Alert variant="success" className="mt-3">
                        {successMessage}
                    </Alert>
                )}

                <div className="row mt-3 gy-3">
                    <div className="col-6 col-sm-6 col-md-3">
                        <div className="p-3 serverStatBox">
                            {vmInfo ? (
                                <>
                                    <UsageProgress label="CPU Usage" now={vmInfo.cpu * 100} max={100} />
                                    <UsageProgress label="RAM Usage" now={vmInfo.mem / 1024 / 1024} max={vmInfo.maxmem / 1024 / 1024} />
                                </>
                            ) : (
                                <p className="text-white">Loading...</p>
                            )}
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3">
                        <div className="p-3 serverStatBox">
                            <h3 className="text-white">Region:</h3>
                            <p>{cloud && cloud.region}</p>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3">
                        <div className="p-3 serverStatBox">
                            <ul className="list-unstyled">
                                <li className="text-white">Status: <span className={`text-${vmInfo && vmInfo.status === 'running' ? 'success' : 'danger'}`}>{vmInfo && vmInfo.status}</span></li>
                                <li className="text-white">IP: {cloud && cloud.ip}</li>
                                <li className="text-white">Hostname: {cloud && cloud.hostname}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3">
                        <div className="p-3 serverStatBox">
                            <div className="d-grid gap-2 d-flex justify-content-center">
                                <button onClick={() => instanceAction('start', false)} className="bootOption text-white"><FontAwesomeIcon icon={faPlay} /></button>
                                <button onClick={() => instanceAction('reboot', false)} className="bootOption text-white"><FontAwesomeIcon icon={faArrowsRotate} /></button>
                                <button onClick={() => instanceAction('stop', false)} className="bootOption text-white"><FontAwesomeIcon icon={faStop} /></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4 mb-0">
                    <div className="col-12 col-lg-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-title">Cloud VPS</div>
                                <ul className="serversMenu p-0">
                                    <li className="list-item"><a onClick={() => menuChange('default')} href="javascript:void(0)" className="nav-link">Overview</a></li>
                                    {/* <li className="list-item my-2 p-1"><a onClick={() => menuChange('firewall')} href="javascript:void(0)" className="nav-link">Firewall</a></li> */}
                                    {/* <li className="list-item my-2 p-1"><a onClick={() => menuChange('operating_system')} href="javascript:void(0)" className="nav-link">Change OS</a></li> */}
                                    {/* <li className="list-item my-2 p-1"><a onClick={() => menuChange('network')} href="javascript:void(0)" className="nav-link">Network</a></li> */}
                                    {/* <li className="list-item my-2 p-1"><a onClick={() => menuChange('package')} href="javascript:void(0)" className="nav-link">Package</a></li> */}
                                    {cloud && cloud.feature_backups === 1 && <li className="list-item my-2 p-1"><a onClick={() => menuChange('backup')} href="javascript:void(0)" className="nav-link">Backups</a></li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-9">
                        <div className="card p-3" hidden={cloudDetailsBlock}>
                            <div className="row">
                                {/* <div className="col">
                                    <h6 className="card-title">Overview</h6>
                                </div>
                                <div className="col">
                                    <h6 className="card-title">Invoices</h6>
                                </div> */}
                            </div>
                            {loading && (
                                <div className="text-center p-3">
                                    <Spinner animation="border" variant="primary" />
                                    <h6 className="text-center">Getting cloud details</h6>
                                </div>
                            )}

                        </div>
                        <div className="card p-3" hidden={backupBlock}>
                            <div className="card-body">
                                <h5>Available Backups</h5>
                                {cloudBackups && cloudBackups.length > 0 ? (
                                    <div className="row">
                                        {cloudBackups.map((backup, index) => (
                                            <div className="col-12 col-md-6 col-lg-4 mb-4" key={index}>
                                                <div className="backup-block p-3">
                                                    <h6 className="mb-2">Backup Date</h6>
                                                    <p>{new Date(backup.ctime * 1000).toLocaleString()}</p>
                                                    <h6 className="mb-2">Size</h6>
                                                    <p>{(backup.size / (1024 ** 3)).toFixed(2)} GB</p>
                                                    <h6 className="mb-2">Notes</h6>
                                                    <p>{backup.notes || 'N/A'}</p>
                                                    <button
                                                        className="btn btn-primary btn-sm w-100"
                                                        onClick={() => handleRestore(backup.volid)}
                                                    >
                                                        Restore
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p className="text-white">No backups available for this VM.</p>
                                )}
                            </div>
                            <div className="card p-3" hidden={fireWallBlock}>
                                {/* Firewall block */}
                            </div>
                            <div className="card p-3" hidden={osBlock}>
                                {/* Operating system block */}
                            </div>
                            <div className="card p-3" hidden={networkBlock}>
                                {/* Network block */}
                            </div>
                            <div className="card p-3" hidden={packageBlock}>
                                {/* Package block */}
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmationModal isOpen={showConfirmation} type="instanceAction" onHide={hideConfirmationBox} onConfirm={() => instanceAction(actionType.toString(), true)} />
            </div>
        </div>
    );
};
