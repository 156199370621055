import { useEffect, useState } from "react";
import { Col, Row, Card, Form, Alert, Button, Badge } from "react-bootstrap";
import './DeployWebhosting.css';
import Navigation from '../../../components/Navigation/Navigation';
import productService from "../../../services/product.service";

export const DeployWebhosting = () => {
    const [packages, setPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [hostingLabel, setHostingLabel] = useState('');
    const [disableCheckout, setDisableCheckout] = useState(true);
    const [checkOutError, setCheckoutError] = useState(false);

    const getHostingPackages = async () => {
        try {
            const products = await productService.getProductsByType('hosting');
            const productsData = products.data.filter((product) => product.status !== 'inactive');

            setPackages(productsData);
        } catch (error) {
            console.error("Error fetching hosting packages:", error);
        }
    };

    const selectPackage = (packageId) => {
        const allPackages = Object.values(packages).flat();
        const packageToSelect = allPackages.find(pkg => pkg.id === packageId) || null;

        setSelectedPackage(packageToSelect);
        setDisableCheckout(!packageToSelect);
        setHostingLabel('');
    };

    const createOrder = () => {
        if (hostingLabel.length < 4 || !selectedPackage) {
            setCheckoutError(true);
            return;
        }

        setCheckoutError(false);
        const serverDetails = [{
            hostingLabel: hostingLabel,
            productType: 'hosting',
            planId: selectedPackage.id,
            details: null
        }];

        localStorage.setItem('temp_order_details', JSON.stringify(serverDetails));
        window.location.href = '/checkout';
    };

    useEffect(() => {
        getHostingPackages();
    }, []);

    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Deploy Hosting</h4>
                <h6 className="mt-1 breadcrumb">
                    <a className="breadcrumb" href="/dashboard">Dashboard</a> /
                    <a className="breadcrumb" href="/products/hosting">Web Hosting</a> / Deploy
                </h6>
                <Row className="mt-4">
                    <Col md={12}>
                        <Card className="shadow-sm">
                            <Card.Body>
                                <h3 className="color-primary mt-2 text-center">Choose Your Hosting Package</h3>
                                <Row className="mt-4">
                                    {packages && packages.length > 0 ? Object.values(packages).flat().map(pack => (
                                        <Col key={pack.id} md={6} lg={4} className="mb-4">
                                            <div
                                                className={`hostingPackage p-3 rounded shadow-sm ${selectedPackage?.id === pack.id ? 'selectedPackage' : ''}`}
                                                data-key={pack.id}
                                                onClick={() => selectPackage(pack.id)}
                                            >
                                                <h5 className="fw-bold">{pack.name}</h5>
                                                <p>€{pack.price}</p>
                                            </div>
                                        </Col>
                                    )) : <p>No packages found.</p>}
                                </Row>
                                {selectedPackage && (
                                    <div className="row mt-5">
                                        {checkOutError && (
                                            <Alert variant="danger" dismissible>
                                                <span className="fw-bold">Error:</span> Make sure you fill in the required fields.
                                            </Alert>
                                        )}
                                        <Form.Group className="pb-2" controlId="formhostingLabel">
                                            <p>Webhosting Name</p>
                                            <Row>
                                                <Col md={6} xs={12} className="pb-3">
                                                    <Form.Control
                                                        type="text"
                                                        value={hostingLabel}
                                                        onChange={(e) => setHostingLabel(e.target.value)}
                                                        maxLength={16}
                                                        placeholder="Enter server name"
                                                    />
                                                </Col>
                                                <Col md={6} xs={12}>
                                                    <Button
                                                        variant="primary"
                                                        disabled={disableCheckout}
                                                        onClick={createOrder}
                                                    >
                                                        Checkout
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
