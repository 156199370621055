import { useEffect } from "react";
import './OSimage.css';
import debianImage from '../../images/os/icon-debian.png'

const OSimage = (props) => {

    return (
        <>
            <div className="d-flex justify-content-start">
                <img height={props.height ? props.height : '25px'} src={debianImage} />
            </div>
        </>
    )
}
export default OSimage;