import { useEffect, useState } from 'react'
import './Tickets.css';
import Navigation from '../../components/Navigation/Navigation'
import { Badge } from 'react-bootstrap'
import TicketTable from '../../components/Table/TicketTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import apiService from '../../services/apiService';
import ticketService from '../../services/ticket.service';


const Tickets = () => {
    const [tickets, setTickets] = useState(null)

    const getTickets = async () => {
        let ticketData = await ticketService.getTickets();

        setTickets(ticketData)
    }
    useEffect(() => {
        getTickets()
    }, [])

    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Support</h4>
                <h6 className="mt-1 breadcrumb"><a className="breadcrumb" href="/dashboard">Dashboard</a> / Support</h6>
                <div className="row mt-2 g-2 d-none d-md-flex">
                    <div onClick={() => window.location = 'mailto:support@centnodes.com'} className="col-12 col-sm-6 col-md-3">
                        <div className='supportBox text-center p-3 h-100 card'>
                            <h6>Email</h6>
                            <Badge>Response within 24 hours.</Badge>
                            <p className="mt-2 supportBoxTextTickets" >Reach out to us via email, our team is ready for any question.</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = '/tickets'} className="col-12 col-sm-6 col-md-3">
                        <div className='supportBox text-center p-3 h-100 card'>
                            <h6>Support Ticket</h6>
                            <Badge>Response within 24 hours.</Badge>
                            <p className="mt-2 supportBoxTextTickets">Reach out to us via a support Ticket, our team will pickup your ticket as soon as possible.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3">
                        <div className='supportBox text-center p-3 h-100 card'>
                            <h6>Live chat</h6>
                            <Badge>Response within 5 minutes.</Badge>
                            <p className="mt-2 supportBoxTextTickets">Reach out to us via livechat and talk to one of our team members.</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = '/knowledgebase'} className="col-12 col-sm-6 col-md-3">
                        <div className='supportBox text-center p-3 h-100 card'>
                            <h6>Knowledgebase</h6>
                            <Badge>Take a look.</Badge>
                            <p className="mt-2 supportBoxTextTickets">Check if we might already got your resolution in our knowledgebase.</p>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="p-3 card">
                            <h6 className="d-flex justify-content-between">Tickets<div className="cardTitleButtons"><button onClick={() => window.location.href = '/ticket/new'} className="btn-sm btn-centhost"><FontAwesomeIcon icon={faPlus} /> New</button></div></h6>
                            {tickets ? <TicketTable data={tickets.data} rowsPerPage={15} refreshFunction={getTickets}></TicketTable> : <div className="text-center mt-5  mx-auto"><p>No tickets.</p></div>}

                        </div>
                    </div>
                </div>
                <div className="row mt-2 g-2 d-flex d-md-none">
                    <div onClick={() => window.location = 'mailto:support@centnodes.com'} className="col-12 col-sm-6 col-md-3">
                        <div className='supportBox text-center p-3 h-100 card'>
                            <h6>Email</h6>
                            <Badge>Response within 24 hours.</Badge>
                            <p className="mt-2 supportBoxTextTickets" >Reach out to us via email, our team is ready for any question.</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = '/tickets'} className="col-12 col-sm-6 col-md-3">
                        <div className='supportBox text-center p-3 h-100 card'>
                            <h6>Support Ticket</h6>
                            <Badge>Response within 24 hours.</Badge>
                            <p className="mt-2 supportBoxTextTickets">Reach out to us via a support Ticket, our team will pickup your ticket as soon as possible.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3">
                        <div className='supportBox text-center p-3 h-100 card'>
                            <h6>Live chat</h6>
                            <Badge>Response within 5 minutes.</Badge>
                            <p className="mt-2 supportBoxTextTickets">Reach out to us via livechat and talk to one of our team members.</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.assign('https://centnodes.com/knowledgebase')} className="col-12 col-sm-6 col-md-3">
                        <div className='supportBox text-center p-3 h-100 card'>
                            <h6>Knowledgebase</h6>
                            <Badge>Take a look.</Badge>
                            <p className="mt-2 supportBoxTextTickets">Check if we might already got your resolution in our knowledgebase.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Tickets