import { useEffect, useState } from 'react';
import './CreateTicket.css';
import Navigation from '../../components/Navigation/Navigation';
import ticketService from '../../services/ticket.service';
import { Alert } from 'react-bootstrap';
import orderService from '../../services/order.service';

const CreateTicket = () => {
    const [formData, setFormData] = useState({
        subject: '',
        type: '',
        priority: '',
        description: '',
        order: 'Other/none',
        files: []
    });
    const [fileError, setFileError] = useState('');
    const [ticketCreated, setTicketCreated] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [orders, setOrders] = useState([]);
    
    // Fetch orders and update the dropdown
    const getUserOrders = async () => {
        try {
            const response = await orderService.getOrders();
            if (response && response.data) {
                setOrders(response.data); // Assuming response.data is the list of orders
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    useEffect(() => {
        getUserOrders();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const validFileTypes = ['image/jpeg', 'image/gif', 'image/png', 'image/webp', 'application/pdf'];

        const invalidFiles = files.filter(file => !validFileTypes.includes(file.type));
        if (invalidFiles.length > 0) {
            setFileError('Invalid file type. Only JPG, GIF, PNG, WEBP, and PDF files are allowed.');
            return;
        }

        setFileError('');
        setFormData({
            ...formData,
            files: e.target.files
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisableButton(true);
        setTicketCreated(false);

        const ticketData = {
            subject: formData.subject,
            type: formData.type,
            priority: formData.priority,
            order: formData.order,
            description: formData.description,
            files: []
        };

        for (const file of formData.files) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                ticketData.files.push({
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    data: reader.result
                });

                if (ticketData.files.length === formData.files.length) {
                    sendTicketData(ticketData);
                }
            };
            reader.onerror = (error) => {
                console.error('Error reading file:', error);
            };
        }

        if (formData.files.length === 0) {
            sendTicketData(ticketData);
        }
    };

    const sendTicketData = async (ticketData) => {
        try {
            let ticket = await ticketService.createTicket(ticketData);
            console.log(ticket);
            if (ticket) {
                setTicketCreated(true);
                setTimeout(() => {
                    window.location.assign("/tickets/" + ticket.data.id);
                }, 3000);
            }
        } catch (error) {
            setDisableButton(false);
            console.error('Error:', error);
            alert('Error submitting the ticket.');
        }
    };

    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Submit a Ticket</h4>
                <h6 className="mt-1 breadcrumb">
                    <a className="breadcrumb" href="/dashboard">Dashboard</a> /
                    <a className="breadcrumb" href="/tickets">Tickets</a> /
                    Submit a Ticket
                </h6>
                <div className="row mt-4">
                    <div className="col-md-8">
                        <div className='card p-3'>
                            <span className='card-title'>Submit a Support Ticket</span>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="subject" className="form-label">Subject</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="subject"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        placeholder="Enter subject"
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="order" className="form-label">Order</label>
                                    <select
                                        className="form-select"
                                        id="order"
                                        name="order"
                                        value={formData.order}
                                        onChange={handleChange}
                                    >
                                        <option value="Other/none">Other/None</option>
                                        {orders.map(order => (
                                            <option key={order.id} value={order.id}>
                                                {order.name || `Order #${order.id} ${order.vendor_product_id}`}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="type" className="form-label">Type</label>
                                    <select
                                        className="form-select"
                                        id="type"
                                        name="type"
                                        value={formData.type}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select type</option>
                                        <option value="billing">Billing</option>
                                        <option value="request">Request</option>
                                        <option value="technical">Technical</option>
                                        <option value="other">Other</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="priority" className="form-label">Priority</label>
                                    <select
                                        className="form-select"
                                        id="priority"
                                        name="priority"
                                        value={formData.priority}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select priority</option>
                                        <option value="high">High</option>
                                        <option value="medium">Medium</option>
                                        <option value="low">Low</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <textarea
                                        className="form-control"
                                        id="description"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        rows="4"
                                        placeholder="Describe your problem"
                                        required
                                    ></textarea>
                                </div>
                                {/* <div className="mb-3">
                                    <label htmlFor="fileUpload" className="form-label">Upload Files</label>
                                    <input
                                        className="form-control"
                                        type="file"
                                        id="fileUpload"
                                        name="files"
                                        multiple
                                        onChange={handleFileChange}
                                    />
                                </div> */}

                                {fileError && <Alert variant={'danger'} className="mt-2">{fileError}</Alert>}
                                {ticketCreated && <Alert variant={'success'} className="mt-2"><span>Thanks! </span>Your ticket has been created. You will be redirected soon.. </Alert>}

                                <button disabled={disableButton} type="submit" className="btn btn-primary w-100">Submit Ticket</button>
                            </form>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='card p-3'>
                            <span className='card-title'>Information</span>
                            <p className='mt-3'>
                                Before submitting a ticket make sure you gather as much information as possible, this ensures that your problem will be solved as quickly as possible.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateTicket;
